import React, {Suspense, lazy, useEffect, useState} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {FallbackView} from '../../theme/partials'
import {BannerCarousel} from '../modules/carousel/BannerCarousel'
import {ManageBanners} from '../pages/manage-banners/ManageBanners'
import {useSharedState} from '../modules/common/SharedStateProvider'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {PermissionManager} from '../pages/permission-management/PermissionManager'
import ViewProfilePage from '../pages/student-profiles/ViewProfilePage'
import EditProfilePage from '../pages/student/pages/EditStudentProfileWrapper'
import UserToProfileRedirect from '../pages/student/pages/internal/UserToProfileRedirect'
import LorQuestionnairePage from '../pages/student/pages/LorQuestionnairePage'
import LorSubmitPage from '../pages/student/pages/LorSubmitPage'
import SopQuestionnairePage from '../pages/student/pages/SopQuestionnairePage'
import SopSubmitPage from '../pages/student/pages/SopSubmitPage'
import ViewLorResponsePage from '../pages/student/pages/ViewLorQuestionnairePage'
import ViewSopResponsePage from '../pages/student/pages/ViewSopQuestionnairePage'
import EssaySubmitPage from '../pages/student/pages/EssaySubmitPage'
import ViewEssayPage from '../pages/student/pages/ViewEssayPage'
import SuggestedUniversityPage from '../pages/university-suggestion/SuggestedUniversityPage'
import SuggestedUniversityStudentPage from '../pages/university-suggestion/SuggestedUniversityStudentPage'
import {GuardedRoute} from './GuardedRoute'
import Permissions from './Permissions'
import {getBanners} from '../pages/manage-banners/BannerService'
import {IBanner} from '../modules/carousel/models/IBanner'
import ViewAllNotificationsPage from '../modules/notification/ViewAllNotificationPage'
import ApproveProfilePage from '../pages/student-profiles/ApproveProfilePage'
import ApproveLorPage from '../pages/student-profiles/ApproveLorPage'
import ApproveSopPage from '../pages/student-profiles/ApproveSopPage'
import MessagesPage from '../modules/messaging/MessagesPage'
import {BulkMessagePage} from '../modules/messaging/BulkMessagePage'
import ReportsPage from '../pages/reports/ReportsPage'
import ReportsNew from '../pages/new-reports/ReportsNew'
import ImpersonatePage from '../pages/user-management/pages/ImpersonatePage'
import StudentEssayPage from '../pages/student/pages/StudentEssayPage'
import {ChangePasswordPage} from '../modules/auth/components/ChangePasswordPage'
import ApproveEssayPage from '../pages/student-profiles/ApproveEssayPage'

const UserManagementPage = lazy(() => import('../pages/user-management/UserManagementWrapper'))
const UniversityManagementPage = lazy(
  () => import('../pages/university-management/UniversityManagementWrapper')
)
const PartnerManagementPage = lazy(
  () => import('../pages/partner-management/PartnerManagementWrapper')
)
const StudentListPage = lazy(() => import('../pages/student-profiles/StudentListPage'))
const TermManagementPage = lazy(() => import('../pages/term-management/TermManagementPage'))
const CountryManagementPage = lazy(
  () => import('../pages/countries-management/CountryManagementPage')
)
const BulkProfileActionsPage = lazy(
  () => import('../pages/bulk-profile-actions/BulkProfileActionsPage')
)
const DownloadPageWrapper = lazy(() => import('../modules/download/DownloadPage'))
const ManageCommonFilesPage = lazy(() => import('../modules/download/ManageCommonFilesPage'))
const SetupEmailAlerts = lazy(() => import('../pages/user-management/pages/SetupEmailAlerts'))
// const CollegeManagementPage = lazy(() => import('../pages/review-colleges/CollegeManagementPage'))
const CourseManagementPage = lazy(
  () => import('../pages/course-management/CourseManagementWrapper')
)
const UpdateSuggestionPartnerPage = lazy(
  () => import('../pages/university-suggestion/UpdateSuggestionPartnerPage')
)
const ViewPartneredSuggestionsPage = lazy(
  () => import('../pages/university-suggestion/ViewPartneredSuggestions')
)

const AlumniList = lazy(() => import('../pages/alumni-list/AlumniList'))

export function PrivateRoutes() {
  const [banners, setBanners] = useState<Array<IBanner>>([])
  const sharedState = useSharedState()

  useEffect(() => {
    //get banners from server
    function fetchData() {
      getBanners().then((resp) => setBanners(resp.data))
    }
    fetchData()
  }, [])

  useEffect(() => {
    if (sharedState.isContentWriter) {
      ;(window as any).botpressWebChat.init({
        composerPlaceholder: 'Chat with IMFS AI',

        botConversationDescription: 'Empowering Dreams. Shaping Careers.',

        botId: '71a168d1-ff3a-4a08-ac9e-292a930a3bbd',

        hostUrl: 'https://cdn.botpress.cloud/webchat/v1',

        messagingUrl: 'https://messaging.botpress.cloud',

        clientId: '71a168d1-ff3a-4a08-ac9e-292a930a3bbd',

        webhookId: '0536e328-034b-4b47-8c84-af0b2c84b37b',

        lazySocket: true,

        themeName: 'prism',

        botName: 'IMFS AI',

        stylesheet:
          'https://webchat-styler-css.botpress.app/prod/code/a3781bd6-18ec-43ed-a22a-976a7020f082/v34485/style.css',

        frontendVersion: 'v1',

        enableConversationDeletion: true,

        theme: 'prism',

        themeColor: '#2563eb',
      })
    }
  }, [sharedState.isContentWriter])

  useEffect(() => {
    if (sharedState.isStudent) {
      ;(window as any).botpressWebChat.init({
        composerPlaceholder: 'Chat with IMFS Portal AI',

        botConversationDescription: 'Empowering Dreams. Shaping Careers',

        botId: 'b67587f6-334a-48d8-a28c-d89b486a58ab',

        hostUrl: 'https://cdn.botpress.cloud/webchat/v1',

        messagingUrl: 'https://messaging.botpress.cloud',

        clientId: 'b67587f6-334a-48d8-a28c-d89b486a58ab',

        webhookId: '28ffc9b3-a94a-443e-b0b8-7247bc38c1df',

        lazySocket: true,

        themeName: 'prism',

        botName: 'IMFS Portal AI',

        website: 'https://www.imfs.co.in',

        stylesheet:
          'https://webchat-styler-css.botpress.app/prod/code/a3781bd6-18ec-43ed-a22a-976a7020f082/v34485/style.css',

        frontendVersion: 'v1',

        enableConversationDeletion: true,

        theme: 'prism',

        themeColor: '#2563eb',
      })
    }
  }, [sharedState.isStudent])

  return (
    <Suspense fallback={<FallbackView />}>
      {sharedState.isStudent && (
        <div className='row'>
          <BannerCarousel banners={banners} />
        </div>
      )}
      <Switch>
        <Route path='/home' component={DashboardWrapper} />
        <Route path='/change-password' component={ChangePasswordPage} />

        <Route path='/student/:profileId/edit/lor' component={LorQuestionnairePage} />
        <Route path='/student/:profileId/edit/sop' component={SopQuestionnairePage} />
        <Route path='/student/:profileId/edit/essay' component={StudentEssayPage} />
        <Route path='/student/:profileId/lor/submit' component={LorSubmitPage} />
        <Route path='/student/:profileId/lor/approve' component={ApproveLorPage} />
        <Route path='/student/:profileId/lor' component={ViewLorResponsePage} />
        <Route path='/student/:profileId/sop/submit' component={SopSubmitPage} />
        <Route path='/student/:profileId/sop/approve' component={ApproveSopPage} />
        <Route path='/student/:profileId/essay/approve' component={ApproveEssayPage} />
        <Route path='/student/:profileId/sop' component={ViewSopResponsePage} />
        <Route path='/student/:profileId/preview-essay' component={ViewEssayPage} />
        <Route path='/student/:profileId/essay/submit' component={EssaySubmitPage} />
        <Route path='/student/:profileId/essay' component={StudentEssayPage} />
        <Route path='/student/:profileId/approve' component={ApproveProfilePage} />
        <Route
          path='/student/:profileId/university-suggestions'
          component={SuggestedUniversityStudentPage}
        />
        <Route path='/alumni-list' component={AlumniList} />

        <Route path='/student/:profileId/downloads' component={DownloadPageWrapper} />

        <Route path='/student/:profileId/edit' component={EditProfilePage} />
        <Route path='/student/:userId/redirectToProfileEdit' component={UserToProfileRedirect} />
        <Route exact path='/student/:profileId' component={ViewProfilePage} />

        <GuardedRoute
          path='/users'
          component={UserManagementPage}
          permission={Permissions.Can_List_Users}
        />
        <Route path='/universities' component={UniversityManagementPage} />
        <GuardedRoute
          path='/courses'
          component={CourseManagementPage}
          permission={Permissions.Can_Update_Courses}
        />
        <GuardedRoute
          path='/students'
          component={StudentListPage}
          permission={Permissions.Can_List_Students}
        />
        <GuardedRoute
          path='/terms'
          component={TermManagementPage}
          permission={Permissions.Can_Create_Term}
        />
        <GuardedRoute
          path='/countries'
          component={CountryManagementPage}
          permission={Permissions.Can_Update_Country}
        />
        <GuardedRoute
          path='/permissions'
          component={PermissionManager}
          permission={Permissions.Can_Update_Permissions}
        />
        <GuardedRoute
          path='/banners'
          component={ManageBanners}
          permission={Permissions.Can_Update_Country}
        />
        <GuardedRoute
          path='/university-suggestions/:profileId'
          component={SuggestedUniversityPage}
          permission={Permissions.Can_Suggest_University}
        />
        <GuardedRoute
          path='/update-suggestion-partner/:profileId'
          component={UpdateSuggestionPartnerPage}
          permission={Permissions.Can_Update_Suggestion_Partner}
        />
        <GuardedRoute
          path='/view-partnered-suggestions'
          component={ViewPartneredSuggestionsPage}
          permission={Permissions.Can_Update_Suggestion_Partner}
        />

        <GuardedRoute
          path='/reports'
          component={ReportsPage}
          permission={Permissions.Can_Generate_Report}
        />
        <GuardedRoute
          path='/all-reports-new'
          component={ReportsNew}
          permission={Permissions.Can_Generate_Report}
        />
        <GuardedRoute
          path='/manage-common-files'
          component={ManageCommonFilesPage}
          permission={Permissions.Can_Manage_Files}
        />
        <GuardedRoute
          path='/:profileId/setup-email-alerts'
          component={SetupEmailAlerts}
          permission={Permissions.Can_Create_Student}
        />

        <Route path='/bulk-actions' component={BulkProfileActionsPage} />
        <Route path='/notifications' component={ViewAllNotificationsPage} />
        <Route path='/messages' component={MessagesPage} />
        <Route path='/message/:conversationId' component={MessagesPage} />
        <Route path='/bulk-message/' component={BulkMessagePage} />
        <Route path='/impersonate/:email' component={ImpersonatePage} />
        {/* <GuardedRoute path='/manage-colleges' component={CollegeManagementPage} permission={Permissions.Can_Manage_Colleges} /> */}
        <GuardedRoute
          path='/partners'
          component={PartnerManagementPage}
          permission={Permissions.Can_List_Partners}
        />

        <Redirect from='/auth' to='/home' />
        <Redirect exact from='/' to='/home' />
        <Redirect exact from='/dashboard' to='/home' />
        <Redirect to='error/404' />
      </Switch>
    </Suspense>
  )
}
