/* eslint-disable react-hooks/exhaustive-deps, @typescript-eslint/no-unused-vars*/
import clsx from 'clsx'
import {FieldArray, Form, Formik, setNestedObjectValues, Field} from 'formik'
import {FC, useEffect, useState} from 'react'
import {Button, Spinner} from 'react-bootstrap-v5'
import {Link, RouteComponentProps, withRouter} from 'react-router-dom'
import Notice from '../../../modules/common/components/Notice'
import {IProfileState, IStudentConfig} from '../models'
import Select from 'react-select'
import {getConfig} from '../services/StudentProfileService'
import * as Yup from 'yup'
import {toast} from 'react-toastify'
import {PageTitle} from '../../../../theme/layout/core'
import {
  deleteEssayForStudent,
  getEssaysForStudent,
  StudentEssayRequestDto,
  StudentEssayResponseDto,
  upsertEssayForStudent,
} from '../services/EssayService'
import {AsyncDropdown} from '../../../modules/common/components/AsyncDropdown'
import {getAllUniversitiesApi} from '../../reports/ReportService'

type Props = {
  profileId: string
}

const validationSchema = Yup.object().shape({
  header: Yup.string().required().label('Topic'),
  essay: Yup.string().required().label('essay'),
})

const newValidationSchema = Yup.object().shape({
  description: Yup.string().required('A Description is Required!'),
  questionandanswers: Yup.array()
    .of(
      Yup.object().shape({
        question: Yup.string().required('A Question is Required!'),
        answer: Yup.string().required('An Answer is Required!'),
        wordcount: Yup.number().required('A Word Count is Required'),
      })
    )
    .min(1, 'At least one question and answer is required'),
  UniversityId: Yup.number().required('University Is Required'),
})

const countWords = (text: string): number => {
  return text
    ? text
        .trim()
        .split(/\s+/)
        .filter((word) => word.length > 0).length
    : 0
}

const StudentEssayPage: FC<RouteComponentProps<Props>> = ({
  history,
  match: {
    params: {profileId},
  },
}) => {
  const [loading, setLoading] = useState(false)
  const [conf, setConfig] = useState<IStudentConfig>()
  const essayDetail: StudentEssayRequestDto = {
    EssayId: 0,
    Description: '',
    questionandanswers: [
      {
        question: '',
        answer: '',
        wordcount: 0,
      },
    ],
    UniversityId: 0,
  }
  const [essays, setEssays] = useState<StudentEssayRequestDto[]>([essayDetail])
  const [essaySubmitted, setEssaySubmitted] = useState<any[]>()
  const [university, setUniversity] = useState<string | null>(null)

  const [refreshKey, setRefreshKey] = useState(1)
  const [profileState, setProfileState] = useState<IProfileState>()
  const [hasPendingPayment, setHasPendingPayment] = useState<boolean>(false)
  interface University {
    id: number
    name: string
    district: string | null
  }
  interface SelectUniversity {
    value: number
    label: string
  }
  const [universityOptions, setUniversityOptions] = useState<SelectUniversity[]>([])
  useEffect(() => {
    fetchUniversities()
  }, [])

  useEffect(() => {
    function fetchData() {
      setLoading(true)
      getConfig(+profileId)
        .then((resp) => {
          setConfig(resp.data)
          setProfileState(resp.data.profileState)
          if (resp.data.overridePaymentRule) {
            setHasPendingPayment(false)
          } else if (resp.data.balanceFees) {
            setHasPendingPayment(true)
          }
        })
        .catch((err) => setHasPendingPayment(true))
        .finally(() => setLoading(false))
    }

    fetchData()
  }, [profileId])

  // useEffect(() => {
  //   async function fetchData() {
  //     setLoading(true);
  //     try {
  //       const essayObj = localStorage.getItem('essay');
  //       if (essayObj) {
  //         const essay = JSON.parse(essayObj);
  //         console.log(essay);
  //         const formData = new FormData();
  //         formData.append('EssayId', essay.essayId);
  //         formData.append('ProfileId', essay.profileId);
  //         const {data} = await getEssaysForStudent(formData);
  //         console.log(data);
  //         // setEssaySubmitted(data?.StudentEssay);
  //       }
  //     } finally {
  //       setLoading(false);
  //     }
  //   }

  //   fetchData();
  // }, [refreshKey]);

  const handleDelete = (essayId: number) => {
    if (essayId === 0) {
      // remove from local
      setRefreshKey(Math.random())
      return
    }

    setLoading(true)
    deleteEssayForStudent(essayId)
      .then(() => {
        setRefreshKey(Math.random())
        toast('Answers deleted successfully', {type: 'success'})
      })
      .finally(() => setLoading(false))
  }

  const handleSubmit = (profileId: string, values: StudentEssayRequestDto) => {
    const finalVal: any = values
    setLoading(true)
    let fetchedQuestionAnswers: any = values.questionandanswers.map(({wordcount, ...rest}) => rest)
    const description = finalVal?.description
    const universityId = finalVal?.UniversityId
    // const removeKeys = ['createdAt', 'createdBy', 'updatedBy', 'updatedAt', 'numberOfEssays', 'id'];
    // removeKeys.forEach((key: any) => delete fetchedVal[key]);

    let formData = new FormData()
    formData.append('ProfileId', profileId)
    // formData.append('EssayId', values.id.toString());
    formData.append('description', description.toString())
    formData.append('universityId', universityId)
    formData.append('questionsandanswers', JSON.stringify(fetchedQuestionAnswers))
    // formData.append('numberOfEssays', values?.numberOfEssays?.toString() || '');
    upsertEssayForStudent(formData)
      .then((res: any) => {
        console.log(res)
        if (res.data.mtype === 'success') {
          setRefreshKey(Math.random())
          // const essayObj = {
          //   profileId: values.profileId.toString(),
          //   essayId: res.data.essayid,
          // };
          // localStorage.setItem('essay', JSON.stringify(essayObj));
          toast(res.data.message, {type: 'success'})
        }
      })
      .catch((err: any) => {
        console.log(err)
        toast('Something went wrong', {type: 'error'})
      })
      .finally(() => setLoading(false))
  }

  const fetchUniversities = async () => {
    try {
      const res = await getAllUniversitiesApi()
      const formattedOptions = res.data.map((university: University) => ({
        label: university.name,
        value: university.id,
      }))
      setUniversityOptions(formattedOptions)
    } catch (err) {
      console.error('Error Fetching Universities', err)
    }
  }

  if (!loading && profileState && !profileState.isApproved) {
    return <Notice message='This module is not yet active as your profile is not approved.' />
  }

  // if (!loading && (hasPendingPayment)) {
  //     return <Notice message='This module is not yet active due to pending payment.' />
  // }

  return (
    <>
      <PageTitle description='Manage Essays' breadcrumbs={[]}>
        Essays
      </PageTitle>
      {!loading && !profileId ? (
        <Notice message='Profile not found' />
      ) : (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header'>
            <h3 className='card-title mb-2'>Essays</h3>
            <Button className='btn btn-sm btn-outline' variant='outline'>
              <Link to={`/student/${profileId}/essays`}>
                <i className='bi bi-view-list'></i> Preview
              </Link>
            </Button>
          </div>
          <div className='card-body pt-9 pb-0'>
            <div className='row mb-10'>
              {!conf?.requiresEssay && (
                <div className='col-xl-6'>
                  <Notice message='Profile does not require Essay.' />
                </div>
              )}
              {loading && <Spinner variant='primary' animation='grow' />}

              {/* {sharedState.isStudent && profileState && profileState.isSopQuestionnaireApproved && <>
                                <div className='col-xl-12'>
                                    <Notice message='ESSAY has been approved.' />
                                </div>
                            </>} */}

              {conf?.requiresEssay && !loading && (
                // {!loading &&
                <>
                  <Button
                    className='btn btn-sm btn-primary col-2'
                    onClick={() => {
                      if (
                        conf?.numberOfEssays &&
                        essays?.length &&
                        conf?.numberOfEssays <= essays.length
                      ) {
                        toast(`Max ${conf.numberOfEssays} essays`, {type: 'error'})
                        return
                      }
                      setEssays(() => [
                        ...(essays || []),
                        {
                          Description: '',
                          questionandanswers: [{question: '', answer: '', wordcount: 0}],
                          UniversityId: 0,
                          EssayId: 0,
                        },
                      ])
                    }}
                  >
                    Add Another
                  </Button>

                  <Button
                    className='btn btn-sm btn-primary col-2 mx-1'
                    onClick={() => history.push(`/student/${profileId}/essay/submit`)}
                  >
                    View All
                  </Button>

                  {essays &&
                    essays.map((essayDetail) => {
                      return (
                        <div
                          className='d-flex flex-row-fluid flex-center bg-white rounded'
                          key={`selectedLor_${essayDetail.EssayId}`}
                        >
                          <Formik
                            initialValues={essayDetail}
                            onSubmit={(values) => {
                              handleSubmit(profileId, values)
                            }}
                            validationSchema={newValidationSchema}
                          >
                            {({...formik}) => (
                              <Form className='row w-60'>
                                <div className='col-xl-12 mb-10'>
                                  {/* <label className='form-label fw-bolder text-dark fs-6 required'>{formik.values.header || 'Essay Topic'}</label>
                                                                <input
                                                                    placeholder='Essay Topic'
                                                                    autoComplete='off'
                                                                    {...formik.getFieldProps(`header`)}
                                                                    className={clsx(
                                                                        'form-control form-control-sm ',
                                                                        {
                                                                            'is-invalid': formik.touched.header && formik.errors.header,
                                                                        },
                                                                        {
                                                                            'is-valid': formik.touched.header && !formik.errors.header,
                                                                        }
                                                                    )}
                                                                />
                                                                {formik.touched.header && formik.errors.header && (
                                                                    <div className='fv-plugins-message-container'>
                                                                        <div className='fv-help-block text-danger mt-2'>
                                                                            <span role='alert'>Answer is Required</span>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                                <div className="col-xl-10 my-5">
                                                                    <label className='form-label fw-bolder text-dark fs-6 required'>{'Essay Content'}</label>
                                                                    <textarea
                                                                        placeholder='Essay Content'
                                                                        autoComplete='off'
                                                                        rows={20}
                                                                        {...formik.getFieldProps(`essay`)}
                                                                        className={clsx(
                                                                            'form-control form-control-sm ',
                                                                            {
                                                                                'is-invalid': formik.touched.essay && formik.errors.essay,
                                                                            },
                                                                            {
                                                                                'is-valid': formik.touched.essay && !formik.errors.essay,
                                                                            }
                                                                        )} />
                                                                    {formik.touched.essay && formik.errors.essay && (
                                                                        <div className='fv-plugins-message-container'>
                                                                            <div className='fv-help-block text-danger mt-2'>
                                                                                <span role='alert'>Answer is Required</span>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div> */}
                                  <label className='form-label fw-bolder text-dark fs-6 required'>
                                    {university || 'University Name'}
                                  </label>
                                  <Select
                                    options={universityOptions}
                                    onChange={(option) => {
                                      setUniversity(option ? option?.label : 'Select a University')
                                      formik.setFieldValue('UniversityId', option?.value ?? 0)
                                    }}
                                    value={universityOptions.find(
                                      (option) => option.value === formik.values.UniversityId
                                    )}
                                    className={clsx(
                                      'form-control-sm',
                                      {
                                        'is-invalid':
                                          formik.touched.UniversityId && formik.errors.UniversityId,
                                      },
                                      {
                                        'is-valid':
                                          formik.touched.UniversityId &&
                                          !formik.errors.UniversityId,
                                      }
                                    )}
                                  />
                                  <label className='form-label fw-bolder text-dark fs-6 required'>
                                    {formik.values.Description || 'Essay Description'}
                                  </label>
                                  <input
                                    placeholder='Essay Topic'
                                    autoComplete='off'
                                    {...formik.getFieldProps(`description`)}
                                    className={clsx(
                                      'form-control form-control-sm ',
                                      {
                                        'is-invalid':
                                          formik.touched.Description && formik.errors.Description,
                                      },
                                      {
                                        'is-valid':
                                          formik.touched.Description && !formik.errors.Description,
                                      }
                                    )}
                                  />
                                  {formik.touched.Description && formik.errors.Description && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block text-danger mt-2'>
                                        <span role='alert'>Answer is Required</span>
                                      </div>
                                    </div>
                                  )}
                                  <div className='col-xl-10 my-5'>
                                    <FieldArray name='questionandanswers'>
                                      {({push, remove}) => (
                                        <div className='space-y-4 mt-4'>
                                          {formik.values.questionandanswers.map((_, index) => (
                                            <div key={index} className='border p-4 mb-4'>
                                              <div className='mb-3'>
                                                <label className='form-label fw-bolder text-dark fs-6 required'>
                                                  Question {index + 1}
                                                </label>
                                                <Field
                                                  name={`questionandanswers.${index}.question`}
                                                  className='form-control form-control-sm'
                                                  placeholder='Enter question'
                                                />
                                              </div>
                                              <div className='mb-3'>
                                                <label className='form-label fw-bolder text-dark fs-6 required'>
                                                  Answer Word Count
                                                </label>
                                                <Field
                                                  name={`questionandanswers.${index}.wordcount`}
                                                  className='form-control form-control-sm'
                                                  placeholder='Enter Word Count'
                                                  type='number'
                                                />
                                              </div>
                                              <div className='mb-3'>
                                                <label className='form-label fw-bolder text-dark fs-6 required'>
                                                  Answer {index + 1}
                                                </label>
                                                <Field name={`questionandanswers.${index}.answer`}>
                                                  {({field}: any) => (
                                                    <div>
                                                      <textarea
                                                        {...field}
                                                        className='form-control form-control-sm mb-2'
                                                        placeholder='Enter answer'
                                                        rows={4}
                                                      />
                                                      <div className='d-flex justify-content-between'>
                                                        <small
                                                          className={`text-${
                                                            countWords(field.value) >
                                                            (formik.values.questionandanswers[index]
                                                              .wordcount || 0)
                                                              ? 'danger'
                                                              : 'muted'
                                                          }`}
                                                        >
                                                          Words: {countWords(field.value)} /{' '}
                                                          {formik.values.questionandanswers[index]
                                                            .wordcount || 0}
                                                        </small>
                                                      </div>
                                                    </div>
                                                  )}
                                                </Field>
                                              </div>
                                              {formik.values.questionandanswers.length > 1 && (
                                                <button
                                                  type='button'
                                                  className='btn btn-sm btn-danger'
                                                  onClick={() => remove(index)}
                                                >
                                                  Remove
                                                </button>
                                              )}
                                            </div>
                                          ))}
                                          <button
                                            type='button'
                                            className='btn btn-sm btn-secondary'
                                            onClick={() =>
                                              push({question: '', answer: '', wordCount: 300})
                                            }
                                          >
                                            Add Question
                                          </button>
                                        </div>
                                      )}
                                    </FieldArray>
                                  </div>
                                </div>
                                <div className='d-flex pt-10'>
                                  <div className='m-2'>
                                    <button
                                      type='button'
                                      className='btn btn-md btn-primary w-100 mb-5'
                                      onClick={async () => {
                                        const validationErrors = await formik.validateForm()
                                        if (Object.keys(validationErrors).length > 0) {
                                          formik.setTouched(
                                            setNestedObjectValues(validationErrors, true)
                                          )
                                          return
                                        }
                                        formik.submitForm()
                                      }}
                                    >
                                      <i className='bi bi-box-arrow-down'></i>
                                      {!loading && 'Save'}
                                      {loading && (
                                        <span
                                          className='indicator-progress'
                                          style={{display: 'inline-block'}}
                                        >
                                          Please wait...{' '}
                                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                      )}
                                    </button>
                                  </div>
                                  <div className='m-2'>
                                    <button
                                      type='button'
                                      className='btn btn-md btn-secondary me-3'
                                      onClick={() => {
                                        history.push(`/student/${profileId}/essay/submit`)
                                      }}
                                    >
                                      <i className='bi bi-hand-thumbs-up'></i>
                                      Preview and Submit
                                    </button>
                                    <button
                                      type='button'
                                      className='btn btn-md btn-secondary me-3'
                                      onClick={() => {
                                        handleDelete(essayDetail.EssayId)
                                      }}
                                    >
                                      <i className='bi bi-hand-thumbs-up'></i>
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      )
                    })}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default withRouter(StudentEssayPage)
