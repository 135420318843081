import axios from 'axios'

export interface QuestionAnswers {
  question: string
  answer: string
  wordcount: number
}

export interface StudentEssayRequestDto {
  EssayId: number
  Description: string
  UniversityId: number
  questionandanswers: QuestionAnswers[]
}

export interface StudentEssayResponseDto extends StudentEssayRequestDto {
  createdBy: string
  createdAt: string
  updatedBy: string
  updatedAt: string | null
}

const API_URL = process.env.REACT_APP_API_URL
export const ESSAY_API = `${API_URL}/api/v1/student-essay`
export const SUBMIT_ESSAY_API = `${API_URL}/api/v1/ProfileSearch`

// export const getEssaysForStudent = (profileId: number) => {
//   return axios.get<Array<StudentEssayResponseDto>>(`${ESSAY_API}/?profileId=${profileId}`)
// }

export const getEssaysForStudent = (profileId: any) => {
  const formData = new FormData()
  formData.append('ProfileId', profileId)
  return axios.post<any>(`${ESSAY_API}/ViewStudentEssay`, formData)
}

export function approveEssay(profileId: any) {
  const formData = new FormData()
  formData.append('ProfileId', profileId)
  return axios.post(`${SUBMIT_ESSAY_API}/UpdateEssayApproval`)
}

export const upsertEssayForStudent = (req: any) => {
  return axios.post<any>(`${ESSAY_API}/AddStudentEssay`, req)
}

export const deleteEssayForStudent = (reqId: number) => {
  return axios.delete(`${ESSAY_API}/${reqId}`)
}

export const submitEssays = (profileId: number) => {
  return axios.post(`${ESSAY_API}/${profileId}/submit`)
}
