import {FC} from 'react'
import tableIcons from '../../../modules/common/components/MaterialTableIcons'
import {getStudentsPendingEssayPaginated} from '../../student-profiles/services/StudentManager'
import {useHistory} from 'react-router-dom'
import EssayStudentList from '../../student-profiles/components/EssayStudentList'

export const StudentPendingEssayApproval: FC<{onLoad?: (count: number) => void}> = ({onLoad}) => {
  const history = useHistory()

  return (
    <>
      <EssayStudentList
        title='Profiles Pending Essay Approval'
        getStudentsFromServer={getStudentsPendingEssayPaginated}
        actions={[
          {
            icon: tableIcons.ViewColumn as any,
            tooltip: 'View Essay',
            disabled: false,
            onClick: (event: any, rowData: any) => {
              const url = `/student/${rowData?.ProfileId}/essay/approve`
              history.push(url)
            },
          },
        ]}
        disabledFilters={[
          'examDate',
          'statuses',
          'hasCompetitiveExamScores',
          'hasLanguageExamScores',
        ]}
        useDefaultActions={false}
        onLoad={onLoad}
      />
    </>
  )
}
