/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useEffect, useRef, useState} from 'react'
import {Button, ButtonGroup, Card, Spinner} from 'react-bootstrap-v5'
import {RouteComponentProps, withRouter} from 'react-router-dom'
import Notice from '../../../modules/common/components/Notice'
import {IAcademicProfile, StudentProfile} from '../models'
import {
  getConfig,
  getStudentAcademicProfile,
  getStudentProfile,
} from '../services/StudentProfileService'
import {PageTitle} from '../../../../theme/layout/core'
import {ProfileHeader} from '../../student-profiles/components/ProfileHeader'
import {PDFExport} from '@progress/kendo-react-pdf'
import Divider from '@material-ui/core/Divider'
import {ExamScoresOverview} from '../../student-profiles/components/ExamScoresOverview'
import {FutureStudyOverview} from '../../student-profiles/components/FutureStudyOverview'
import {GraduationDetailsOverview} from '../../student-profiles/components/GraduationDetailsOverview'
import {WorkDetailsOverview} from '../../student-profiles/components/WorkDetailsOverview'
import {StudentEssayResponseDto} from '../services/EssayService'
import {getEssaysForStudent} from '../services/EssayService'

type Props = {
  profileId: string
}

const ViewEssayPage: FC<RouteComponentProps<Props>> = ({
  history,
  match: {
    params: {profileId},
  },
}) => {
  return <ViewEssayResponse profileId={profileId} />
}

export default withRouter(ViewEssayPage)

export const ViewEssayResponse: FC<Props> = ({profileId}) => {
  const [requiresEssay, setRequiresEssay] = useState<boolean>()
  const [essays, setEssays] = useState<Array<StudentEssayResponseDto>>()
  const [loading, setLoading] = useState(false)
  const [profile, setProfile] = useState<StudentProfile>()
  const pdfExportComponent = useRef(null)

  useEffect(() => {
    const fetchPersonalProfile = (profileId: number) => {
      getStudentProfile(+profileId)
        .then(({data: serverProfile}) => {
          setProfile(serverProfile)
        })
        .finally(() => setLoading(false))
    }

    if (profileId) {
      fetchPersonalProfile(+profileId)
    }
  }, [profileId])

  const exportPDF = () => {
    if (pdfExportComponent.current) {
      ;(pdfExportComponent.current as any).save()
    }
  }

  useEffect(() => {
    function fetchData() {
      setLoading(true)
      getConfig(+profileId)
        .then((resp) => {
          setRequiresEssay(resp?.data?.requiresEssay)
        })
        .catch((err) => setRequiresEssay(false))
        .finally(() => setLoading(false))
    }
    fetchData()
  }, [profileId])

  useEffect(() => {
    async function fetchData() {
      setLoading(true)
      try {
        const {data: StudentEssay} = await getEssaysForStudent(+profileId)
        // console.log('StudentEssay', StudentEssay.StudentEssay);
        const newArr: any = StudentEssay
        const transformedRes: [] = newArr?.StudentEssay.map((essay: any) => ({
          ...essay,
          questionandanswers: essay.questionandanswers.map(
            ({questionid, ...rest}: {questionid: any; [key: string]: any}) => rest
          ),
        }))
        console.log('transformedRes', transformedRes)

        setEssays(transformedRes || null)
      } finally {
        setLoading(false)
      }
    }
    requiresEssay && fetchData()
  }, [requiresEssay])
  const [academicProfile, setAcademicProfile] = useState<IAcademicProfile>()

  useEffect(() => {
    const fetchAcademicProfile = (profileId: number) => {
      if (!profile) return
      getStudentAcademicProfile(profileId)
        .then(({data: serverProfile}) => {
          if (!serverProfile.workExperiences) {
            serverProfile = {...serverProfile, workExperiences: []}
          }
          setAcademicProfile(serverProfile)
        })
        .catch((err) => {
          //notify TODO
          console.error(err)
        })
    }
    fetchAcademicProfile(+profileId)
  }, [profileId])

  if (!loading && !profileId) {
    ;<Notice message='Profile not found' />
  }

  return (
    <>
      <PageTitle description='Manage SOP Questionnaires' breadcrumbs={[]}>
        View Essays
      </PageTitle>
      <div className='row'>
        <div className='col bg-white pt-2 m-2'>
          <ButtonGroup className='mb-5'>
            <Button
              className='btn btn-sm btn-outline btn-outline-primary'
              variant='outline'
              onClick={exportPDF}
              disabled={!profile || !requiresEssay}
            >
              <i className='bi bi-download text-dark'></i> Download
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <h3 className='card-title mb-2'>Essay Response</h3>
        </div>
        <div className='card-body pt-9 pb-0'>
          {profile && <ProfileHeader showActions={false} exportPDF={null} profile={profile} />}
          <PDFExport
            ref={pdfExportComponent}
            paperSize='auto'
            margin={40}
            fileName={`SOP Export - ${profile?.email}.pdf`}
            author='Aniket Dandawate'
            creator='Aniket Dandawate'
          >
            <div className='row mb-10'>
              {!requiresEssay && (
                <div className='col-xl-6'>
                  <Notice message='Profile does not require SOP.' />
                </div>
              )}
              {loading && <Spinner variant='primary' animation='grow' />}
              {academicProfile && (
                <>
                  <GraduationDetailsOverview academicsData={academicProfile} />
                  <FutureStudyOverview academicsData={academicProfile} />
                  <WorkDetailsOverview data={academicProfile.workExperiences || []} />
                </>
              )}
              {profile && (
                <ExamScoresOverview
                  data={{
                    competitiveExam: profile.competitiveExam,
                    languageExam: profile?.languageExam,
                    profileId: profile.id,
                  }}
                />
              )}
              {requiresEssay && !loading && (
                <div
                  className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid w-100'
                  id='kt_create_account_stepper'
                >
                  {essays == null ? (
                    <div className='d-flex flex-row-fluid flex-center bg-white rounded'>
                      <div className='col-xl-6'>
                        <Notice message='Essays responded by student.' />
                      </div>
                    </div>
                  ) : (
                    <div className='row col-12 bg-white rounded'>
                      {essays.map((a, idx) => {
                        return (
                          <Card
                            className='mb-5 border border-primary p-5 text-justify w-100'
                            key={a.EssayId}
                          >
                            <div className='row mb-5'>
                              <div className='col-12'>
                                <span className='badge badge-info badge-light-info m-2'>
                                  {idx + 1}
                                </span>
                                <p className='text-dark mb-2'>
                                  <label>{a.Description}</label>
                                </p>
                              </div>
                            </div>
                            <Divider variant='fullWidth' className='bg-danger' />
                            <div className='row mt-2'>
                              <div className='col-12 '>
                                <p className='text-dark mb-2'>
                                  <p className='form-label bg-light-warning'>
                                    {a.questionandanswers.map((qa: any, idx: number) => (
                                      <div className='mb-2' key={idx}>
                                        <span>
                                          Q{idx + 1}. {qa.question}
                                        </span>
                                        <br />
                                        <span>Ans. {qa.answer}</span>
                                      </div>
                                    ))}
                                  </p>
                                </p>
                              </div>
                            </div>
                          </Card>
                        )
                      })}
                    </div>
                  )}
                </div>
              )}
            </div>
          </PDFExport>
        </div>
      </div>
    </>
  )
}
